import React from 'react'


const PaperCover = ({ coverImage, title, subtitle, tertiaryTitle, description, linkName, linkURL }) => {

    return (
        <div className='h-full md:h-fit xl:h-fit w-full grid grid-cols-1 grid-rows-1'>

            <img className='col-start-1 row-start-1 left-2 w-auto h-auto drop-shadow-xl z-5' src={coverImage} alt={title + ' paper cover'}/>

            <div className='hidden col-start-1 row-start-1 w-full ws:w-4/5 h-full xl:grid grid-cols-1 items-center justify-center py-10 bg-blue-900 opacity-0 hover:opacity-90 z-10 transition-opacity duration-500 rounded-3xl text-center text-white'>
                
                <h2 className='font-bold text-3xl 2xl:text-4xl ws:text-6xl mb-1'>{title}</h2>
                <h3 className='text-xl ws:text-4xl'>{subtitle}</h3>
                <h4 className='ws:text-4xl'>{tertiaryTitle}</h4>
                <p className='font-light text-center text-xl ws:text-4xl mx-10 mt-5 mb-5'>{description}</p>

                {/* <a className='w-fit flex flex-row items-center justify-self-center self-end bg-white rounded-xl mb-1 px-6 py-2 text-black' href={linkURL} target="_blank">
                    <text className='text-semibold text-2xl'>Press Release</text>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                    </svg>
                </a> */}

                <a className='w-fit flex flex-row items-center justify-self-center self-end bg-white rounded-xl px-6 py-2 ws:py-4 text-black' href={linkURL} target="_blank">
                    <text className='text-semibold text-2xl ws:text-4xl'>{linkName}</text>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                    </svg>
                </a>

            </div>
            
            <div className='xl:hidden h-full flex flex-col items-center bg-shell rounded-xl mt-5 p-5 text-center'>

                <h2 className='font-bold text-3xl mb-1'>{title}</h2>
                <h3 className='text-xl'>{subtitle}</h3>
                <h4>{tertiaryTitle}</h4>
                <p className='font-light text-lg xl:mx-10 mt-5 mb-5'>{description}</p>

                {/* <a className='w-full flex flex-row items-center justify-center bg-white rounded-xl mb-3 px-6 py-2 text-black' href={linkURL} target="_blank">
                    <text className='text-semibold text-xl'>Press Release</text>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                    </svg>
                </a> */}

                <a className='w-full flex flex-row items-center justify-center bg-white rounded-xl px-6 py-2 text-black' href={linkURL} target="_blank">
                    <text className='text-semibold text-xl'>{linkName}</text>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                    </svg>
                </a>

            </div>

            
        </div>
    )
}

export default PaperCover