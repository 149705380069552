import * as React from 'react'
import Layout from '../components/Layout'
import arcanum_turntable from '../images/arcanum_turntable.mp4'
import PaperCover from '../components/PaperCover'
import arcanum_patch from '../images/arcanum-patch.webp'
import arcanum_expansion from '../images/arcanum_expansion.mp4'
import somerville from '../images/somerville.jpg'
import bingham from '../images/bingham.png'
import glex_cover from '../images/glex_cover.png'
import bis_cover from '../images/bis_cover.png'
import iac_cover from '../images/iac_cover.png'


export default function ArcanumPage() {
    return (
        <Layout title='Arcanum | Conex Research'>
            <title>Conex Research</title>
            <section className='lg:grid grid-cols-3 w-screen h-fit px-8 sm:px-20 my-5 sm:my-1 gap-y-3 text-center sm:text-left'>

                <div className='lg:col-span-2 h-fit lg:h-full flex flex-col bg-white dark:bg-neutral-800 lg:mr-7 p-5 md:p-10 lg:p-16 rounded-xl justify-center'>

                    <div className='w-full grid lg:grid-cols-2 justify-items-stretch'>

                        <div className='flex flex-col my-7 lg:my-0'>
                            <h1 className='font-bold text-5xl 2xl:text-8xl ws:text-9xl'>ARCANUM</h1>
                            <text className='text-lg sm:text-xl ws:text-4xl italic'>noun, plural [ ahr-keh-num ]</text>
                            <text className='text-lg sm:text-xl ws:text-4xl font-semibold italic'>Secrets or mysteries</text>
                        </div>

                        <div className='hidden lg:flex flex-row w-auto justify-end'>
                            <img src={arcanum_patch} className='w-auto h-56 aspect-square bg-contain'/>
                        </div>

                    </div>
                    
                    <p className='h-fit lg:mt-16 text-lg md:text-xl lg:text-2xl ws:text-5xl font-light'>
                        Arcanum is a multi-role spacecraft for the outer Solar System consisting of a large Neptunian orbiter and a soft-landing Triton probe. 
                        The spacecraft is designed to be at the forefront of next-generation space science missions, showcasing what will be possible with new super heavy-lift launch vehicles such as SpaceX Starship.
                    </p>

                    <div className='lg:hidden w-auto h-2/5 mt-7 lg:mt-0 justify-end place-self-center'>
                        <img src={arcanum_patch} className='w-auto h-56 aspect-square bg-contain'/>
                    </div>

                </div>

                <div className='flex flex-col h-full mt-8 lg:mt-0 items-center'>

                    <div className='h-4/5 flex flex-col bg-shell dark:bg-neutral-800 rounded-xl drop-shadow-lg overflow-hidden justify-center items-center py-10 ws:py-20'>
                        <video
                            autoPlay
                            loop
                            muted
                            playsInline
                            className='w-[170%] max-w-[170%] object-cover'
                            src={arcanum_turntable}
                        />
                    </div>

                    {/* Set height of turntable div to h-4/5 when button is displayed */}

                    <a href={'https://www.youtube.com/watch?v=ewdYsM_-2g8&t=16s'} target='_blank' className='w-full h-fit flex flex-row justify-center items-center bg-black dark:bg-white text-white dark:text-black rounded-xl mt-5 lg:mt-auto ws:mt-10 p-5 drop-shadow-lg'>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" fill="white" viewBox="0 0 572 512" stroke="currentColor">
                            <path d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"/>
                        </svg>
                        <text className='ml-3 font-semibold text-2xl ws:text-3xl text-white dark:text-black'>Watch the introduction</text>
                    </a>


                    <a href='https://www.youtube.com/watch?v=leVlda9HsNA' target='_blank' className='mt-2'>
                        <text className='font-semibold text-xl ws:text-2xl hover:underline'>Available in Russian (русский)</text>
                    </a>
                </div>

                <div className='flex flex-col lg:col-span-3 items-center justify-center w-full h-fit my-10 p-5 md:p-10 lg:p-20 rounded-xl overflow-hidden bg-shell dark:bg-neutral-800 drop-shadow-lg z-10'>
                   
                    <h1 className='font-bold text-4xl sm:text-7xl ws:text-9xl text-center sm:text-left my-7 lg:my-0 lg:mb-20'>SOMERVILLE ORBITER</h1>

                    <div className='flex flex-col lg:flex-row w-full items-center'>

                        <img src={somerville} className='hidden lg:block w-1/2 bg-contain rounded-xl ws:p-48'/>

                        <p className='lg:w-1/2 h-fit lg:pl-14 text-lg md:text-xl lg:text-2xl ws:text-5xl font-light'>
                            The orbital component Somerville will perform multiple flybys on a 15-year trajectory to Neptune before finally capture burning into a highly-eccentric orbit; providing deep-space observation windows at apogee and planetary science measurements at perigee.<br/><br/>
                            Remote sensing and in-situ measurements ranging from gamma-ray spectrometry to dust detection will allow a comprehensive understanding of the Neptunian environment, and an optical wavelength telescope will allow deep-space observations to be made outside the zodiacal light interference of the inner Solar System.
                        </p>

                        <img src={somerville} className='lg:hidden bg-contain rounded-xl'/>

                    </div>

                </div>

                <div className='w-screen sm:w-full sm:col-span-3 z-5 -ml-8 sm:-ml-0 sm:-mt-10 sm:-mb-16'>
                    <video
                        autoPlay
                        loop
                        muted
                        playsInline
                        className='w-full h-full object-cover'
                        src={arcanum_expansion}
                        type='video/webm'
                    />
                </div>

                <div className='flex flex-col lg:col-span-3 items-center justify-center w-full h-fit mt-10 p-5 md:p-10 lg:p-20 rounded-xl overflow-hidden bg-shell dark:bg-neutral-800 drop-shadow-lg z-10 text-center sm:text-left'>
                    
                    <h1 className='font-bold text-4xl sm:text-7xl ws:text-9xl my-7 lg:my-0 lg:mb-20'>BINGHAM LANDER</h1>

                    <div className='flex flex-col lg:flex-row w-full items-center'>

                        <img src={bingham} className='hidden lg:block w-1/2 bg-contain rounded-xl drop-shadow-md'/>

                        <p className='lg:w-1/2 h-fit lg:pl-14 text-lg md:text-xl lg:text-2xl ws:text-5xl font-light'>
                            The Bingham soft-landing probe will detach from Somerville during a Triton flyby, alongside two surface impact penetrators.<br/><br/>
                            Sequenced landings will allow the conduction of a seismic experiment on the interior structure of the moon, and additional instrumentation will allow near-surface atmosphere, surface, and subsurface measurements to be taken.
                        </p>

                        <img src={bingham} className='lg:hidden mt-6 lg:mt-0 bg-contain rounded-xl drop-shadow-md'/>

                    </div>

                </div>

            </section>

            <section className='flex flex-col w-screen h-full justify-center p-8 xl:p-20 mb-20'>

                <h1 className='xl:hidden text-center my-14 font-semibold text-5xl'>Arcanum Papers</h1>

                <div className='left-0 top-0 h-1/2 grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-16 sm:gap-5 2xl:gap-20'>

                    <div className='hidden xl:flex flex-col items-center justify-self-center p-5 ws:my-auto'>
                        <img className='h-auto w-full object-cover drop-shadow-xl dark:drop-shadow-none rounded-full' src={arcanum_patch}/>
                        <h1 className='text-center sm:my-14 font-semibold text-3xl sm:text-4xl'>Arcanum <br className='hidden sm:inline'/>Papers</h1>
                    </div>

                    <PaperCover coverImage={glex_cover} title={'GLEX 2021'} subtitle={'St Petersburg, Russia'} tertiaryTitle={'June 2021'} description={'An L-class Multirole Observatory and Science Platform for Neptune'} linkName={'View on arXiv'} linkURL={'https://arxiv.org/abs/2106.09409'}/>
                    <PaperCover coverImage={bis_cover} title={'JBIS Vol 74.9'} subtitle={'British Interplanetary Society'} tertiaryTitle={'September 2021'} description={'Scientific Objectives and Instruments for Neptune, Triton and KBOs'} linkName={'View on BIS Shop'} linkURL={'https://bis-space.com/shop/product/jbis-vol-74-no-09-september-2021/'}/>
                    <PaperCover coverImage={iac_cover} title={'IAC 2021'} subtitle={'Dubai, UAE'} tertiaryTitle={'October 2021'} description={'Concept of Operations for a Neptune System Mission'} linkName={'View on IAF'} linkURL={'https://iafastro.directory/iac/paper/id/66849/summary/'}/>

                </div>

            </section>

        </Layout>
    )
}
